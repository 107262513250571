import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, Input, Box, SimpleGrid, Text } from '@chakra-ui/react'
import Loader from 'components/Loader'
import { PairToken } from 'types/pair'
import InputLabel from 'components/InputLabel'
import { useMoralis } from 'react-moralis'
import { networkConfigs } from 'data/networks'

type Props = {
  tokens: PairToken[]
  transactionFee: number
  onUpdate: (
    totalVolume: number,
    decimals: number,
    timeframe: number,
    numberOfTransactions: number,
    slippage: number | null,
    volatility: number | null,
  ) => void
}

const TRANSACTIONS_PER_HOUR = 12

const TargetVolume = ({ tokens, transactionFee, onUpdate }: Props) => {
  const { chainId } = useMoralis()
  const [totalVolume, setTotalVolume] = useState(0)
  const [timeframe, setTimeframe] = useState(0)
  const [numberOfTransactions, setNumberOfTransactions] = useState(0)
  const [slippage, setSlippage] = useState<number | null>(null)
  const [volatility, setVolatility] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    setErrorMessage(null)
    setIsValid(true)
    if (
      Number.isNaN(totalVolume) ||
      Number.isNaN(timeframe) ||
      Number.isNaN(numberOfTransactions) ||
      totalVolume <= 0 ||
      timeframe <= 0 ||
      numberOfTransactions <= 0 ||
      (slippage !== null && slippage <= 0)
    ) {
      setIsValid(false)
      return
    }
    if ((totalVolume / numberOfTransactions) * 1.2 > tokens[1].accountBalance) {
      setIsValid(false)
      setErrorMessage(
        'Not enough balance for volume target. Try lower volume or higher number of transactions.',
      )
      return
    }
    if (numberOfTransactions % 2 !== 0) {
      setIsValid(false)
      setErrorMessage('Number of transactions must be an even number.')
    }
    if (timeframe * TRANSACTIONS_PER_HOUR < numberOfTransactions) {
      setIsValid(false)
      setErrorMessage('Too many transactions for a given timeframe.')
    }
  }, [totalVolume, timeframe, numberOfTransactions, slippage, tokens])

  const handleSubmit = async () => {
    setIsLoading(true)
    await onUpdate(
      totalVolume,
      tokens[1].decimals,
      timeframe,
      numberOfTransactions,
      !Number.isNaN(slippage) ? slippage : null,
      !Number.isNaN(volatility) ? volatility : null,
    )
    setIsLoading(false)
  }

  return (
    <Box>
      <SimpleGrid minChildWidth="120px" columns={2} spacing={8}>
        <Box>
          <Box mb={4}>
            <InputLabel label="Volume" sublabel={tokens[1].symbol} />
            <Input
              type="number"
              placeholder="0.0"
              onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
                setTotalVolume(parseFloat(changeEvent.target.value))
              }}
            />
          </Box>
          <Box mb={4}>
            <InputLabel label="Timeframe" sublabel="Hours" />
            <Input
              type="number"
              placeholder="0"
              onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
                setTimeframe(parseFloat(changeEvent.target.value))
              }}
            />
          </Box>
          <Box>
            <InputLabel label="Number of transactions" />
            <Input
              type="number"
              placeholder="0"
              onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
                setNumberOfTransactions(parseFloat(changeEvent.target.value))
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box mb={4}>
            <InputLabel label="Slippage" sublabel="%" />
            <Input
              type="number"
              placeholder="Optional"
              onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
                setSlippage(parseFloat(changeEvent.target.value))
              }}
            />
          </Box>
          <Box>
            <InputLabel label="Volatility" sublabel="%" />
            <Input
              type="number"
              placeholder="Optional"
              onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
                setVolatility(parseFloat(changeEvent.target.value))
              }}
            />
          </Box>
        </Box>
      </SimpleGrid>
      {totalVolume > 0 && numberOfTransactions > 0 && (
        <Box pt={6}>
          <Text>Tokens Needed per TX</Text>
          <Text fontSize="xl" fontWeight="bold">
            {(totalVolume / numberOfTransactions).toFixed(4)} {tokens[1].symbol}
          </Text>
        </Box>
      )}

      {numberOfTransactions > 0 && (
        <Box pt={6}>
          <Text>Transaction fees</Text>
          <Box>
            <Text fontSize="xl">
              {(numberOfTransactions * transactionFee).toFixed(4)}{' '}
              {chainId && networkConfigs[chainId].currencySymbol}
            </Text>
          </Box>
        </Box>
      )}

      {errorMessage && (
        <Box mt={6} fontSize="sm" fontWeight="bold">
          {errorMessage}
        </Box>
      )}
      {!isLoading ? (
        <Button mt={6} disabled={!isValid} size="sm" w="100%" onClick={handleSubmit}>
          Set Volume
        </Button>
      ) : (
        <Loader size="md" showText={false} />
      )}
    </Box>
  )
}

export default TargetVolume
