import React, { useState } from 'react'
import { Box, Divider, Container, Flex, Text } from '@chakra-ui/react'

type Props = {
  icon: string
  header: string
  children: any
}

const IconText = ({ icon, header, children }: Props) => {
  return (
    <Box flex={1} textAlign="center">
      <Box className={icon} />
      <Box fontSize="2xl" fontWeight="bold" py={3}>
        {header}
      </Box>
      <Box fontSize="lg">{children}</Box>
    </Box>
  )
}

export default IconText
