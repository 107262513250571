import React, { useState, useEffect } from 'react'
import { useMoralis } from 'react-moralis'
import { contracts } from 'data/contracts'
import onboardingAbi from 'data/abi/Onboarding.json'
import { AbiItem } from 'web3-utils'

export const useClientBots = () => {
  const { account, web3, isAuthenticated } = useMoralis()
  const [clientBots, setClientBots] = useState<string[] | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!web3 || !account || !isAuthenticated) {
      setIsLoading(false)
      return
    }
    ;(async () => {
      setIsLoading(true)
      const contract = new web3.eth.Contract(
        onboardingAbi as AbiItem[],
        contracts.onboarding.address,
      )

      const bots = await contract.methods
        .getClientBots(account)
        .call()
        .catch(() => null)

      setClientBots(bots)
      setIsLoading(false)
    })()
  }, [web3, account, isAuthenticated])

  return { clientBots, isLoading }
}
