import React, { useEffect } from 'react'
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom'
import { useMoralis } from 'react-moralis'
import Sidebar from 'components/Sidebar'
import Onboarding from 'pages/Onboarding'
import Dashboard from 'pages/Dashboard'
import { Box, Heading, Spinner } from '@chakra-ui/react'
import Demo from 'pages/Demo'
import Home from 'pages/Home'
import RequireWeb3 from 'components/RequireWeb3/RequireWeb3'

export const App = () => {
  return (
    <BrowserRouter>
      <Sidebar>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/onboarding">
            <RequireWeb3>
              <Onboarding />
            </RequireWeb3>
          </Route>
          <Route path="/dashboard">
            <RequireWeb3>
              <Dashboard />
            </RequireWeb3>
          </Route>
          <Route path="/demo">
            <Demo />
          </Route>
        </Switch>
      </Sidebar>
    </BrowserRouter>
  )
}
