import React from 'react'
import { Box, Flex, Text, useColorModeValue, FlexProps, Button } from '@chakra-ui/react'
import { useMoralis } from 'react-moralis'
import { getEllipsisText } from 'utils/formatters'

interface MobileProps extends FlexProps {
  onOpen: () => void
}

const Navbar = ({ onOpen }: MobileProps) => {
  const { account, isAuthenticated, authenticate, logout } = useMoralis()
  return (
    <>
      <Flex
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 24 }}
        height="20"
        alignItems="center"
        bg="gray.800"
        justifyContent="flex-end"
        display={{ base: 'none', md: 'flex' }}
      >
        {account && isAuthenticated ? (
          <>
            <Text mx={3} fontWeight="bold">
              {getEllipsisText(account)}
            </Text>
            <Button onClick={logout}>Disconnect</Button>
          </>
        ) : (
          <Button onClick={() => authenticate()}>Connect To Wallet</Button>
        )}
      </Flex>
    </>
  )
}

export default Navbar
