import { useMoralis } from 'react-moralis'
import tokenAbi from 'data/abi/Erc20Token.json'
import { AbiItem } from 'web3-utils'

export const useTokenApproval = () => {
  const { web3, account, isAuthenticated } = useMoralis()

  const approveToken = async (spender: string, tokenAddress: string) => {
    if (!web3 || !account || !isAuthenticated) {
      return false
    }
    const contract = new web3.eth.Contract(tokenAbi as AbiItem[], tokenAddress)
    try {
      await contract.methods
        .approve(spender, '1157920892373161954235709850086879078')
        .send({ from: account })
      return true
    } catch {
      return false
    }
  }

  return { approveToken }
}
