import React, { useEffect, useRef } from 'react'
import { Box } from '@chakra-ui/react'
import { ChartData } from 'types/chart'
import { createChart } from 'lightweight-charts'

type Props = {
  chartData: ChartData[]
  height: number
}

const Chart = ({ chartData, height }: Props) => {
  const chartRef = useRef<any>()
  useEffect(() => {
    const chart = createChart(chartRef.current, {
      height,
      layout: {
        backgroundColor: 'transparent',
        textColor: '#FFFFFF',
      },
      grid: {
        vertLines: {
          visible: false,
        },
        horzLines: {
          color: '#4A5568',
        },
      },
      rightPriceScale: {
        autoScale: true,
        invertScale: false,
        alignLabels: true,
        borderVisible: true,
        entireTextOnly: false,
        visible: true,
      },
      timeScale: {
        rightOffset: 5,
        barSpacing: 3,
        fixLeftEdge: true,
        fixRightEdge: true,
        lockVisibleTimeRangeOnResize: true,
        rightBarStaysOnScroll: true,
        borderVisible: false,
        visible: true,
        timeVisible: true,
        secondsVisible: true,
      },
    })
    const series = chart.addCandlestickSeries(chartData[0].options)
    const sortedData = chartData[0].data.sort((a, b) => (a.time < b.time ? -1 : 1))
    series.setData(sortedData)
    series.applyOptions({
      priceScaleId: 'right',
      priceFormat: {
        type: 'price',
        precision: 8,
        minMove: 0.00000001,
      },
    })

    return () => {
      chart.remove()
    }
  }, [chartData, height])

  return <Box ref={chartRef} w="100%" />
}

export default Chart
