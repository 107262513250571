import React from 'react'
import { Text } from '@chakra-ui/react'

type Props = {
  label: string
  sublabel?: string
}

const FormattedNumber = ({ label, sublabel }: Props) => {
  return (
    <>
      <Text>{label}</Text>
      <Text fontSize="sm" opacity={0.5}>
        {sublabel}
      </Text>
    </>
  )
}

export default FormattedNumber
