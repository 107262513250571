import React, { useState, useEffect } from 'react'
import Moralis from 'moralis'
import { useMoralis } from 'react-moralis'
import { AbiItem } from 'web3-utils'
import { ChainId } from 'types/moralis'
import { contracts } from 'data/contracts'
import { BotContract } from 'types/bot'
import { getWeiString } from 'utils/formatters'
import botAbi from 'data/abi/Bot.json'

type Params = {
  address: string | null
}

const PRICE_MULTIPLIER = 18
const SLIPPAGE_MULTIPLIER = 100
const VOLATILITY_MULTIPLIER = 100
const DEFAULT_SLIPPAGE = 0
const DEFAULT_VOLATILITY = 0

const REFRESH_INTERVAL = 60000

export const useBot = ({ address }: Params) => {
  const { account, web3, chainId, isAuthenticated } = useMoralis()
  const [bot, setBot] = useState<BotContract | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const refresh = (withoutLoading = false) => {
    if (!address || !web3 || !account || !isAuthenticated || !chainId) {
      setIsLoading(false)
      return
    }
    ;(async () => {
      if (!withoutLoading) {
        setIsLoading(true)
      }
      const contract = new web3.eth.Contract(botAbi as AbiItem[], address)

      const isEnabled = await contract.methods.isEnabled().call()
      const mod = await contract.methods.mod().call()
      const pairAddress = await contract.methods.pair().call()
      const token0Address = await contract.methods.token1().call()
      const token1Address = await contract.methods.token2().call()
      const notShitcoin = await contract.methods.notShitCoin().call()
      const targetPrice = await contract.methods.targetPrice().call()
      const totalVolume = await contract.methods.totalVolume().call()
      const transactionsTotal = await contract.methods.txTotal().call()
      const transactionsRemaining = await contract.methods.txRemaining().call()
      const transactionsStartTime = await contract.methods.txStartTime().call()
      const transactionsEndTime = await contract.methods.txEndTime().call()
      const fees = await contract.methods.cashoutFee().call()
      const expiration = await contract.methods.expiration().call()
      const transactionFee = await contract.methods.transactionFee().call()

      const notShitcoinDecimals = parseFloat(
        (
          await Moralis.Web3API.token.getTokenMetadata({
            chain: chainId as ChainId,
            addresses: [notShitcoin],
          })
        )[0].decimals,
      )

      const botData = {
        address,
        isEnabled,
        mod: parseInt(mod, 10),
        pairAddress,
        chainId: contracts.onboarding.chainId as ChainId,
        token0Address,
        token1Address,
        targetTokenAddress: token0Address === notShitcoin ? token1Address : token0Address,
        targetPrice: parseFloat(targetPrice) / 10 ** PRICE_MULTIPLIER,
        totalVolume: Moralis.Units.FromWei(totalVolume, notShitcoinDecimals),
        transactionsTotal: parseFloat(transactionsTotal),
        transactionsRemaining: parseFloat(transactionsRemaining),
        transactionsStartTime: parseFloat(transactionsStartTime),
        transactionsEndTime: parseFloat(transactionsEndTime),
        fees: parseFloat(fees) / 10000,
        expiration: parseFloat(expiration),
        transactionFee: Moralis.Units.FromWei(transactionFee, 18),
      }

      setBot(botData)
      if (!withoutLoading) {
        setIsLoading(false)
      }
    })()
  }

  const setTargetPrice = async (
    targetPrice: number,
    timeframeInHours: number,
    numberOfTransactions: number,
    slippage: number | null,
    volatility: number | null,
  ) => {
    if (!address || !web3 || !account || !isAuthenticated || !bot) {
      return false
    }
    const contract = new web3.eth.Contract(botAbi as AbiItem[], address)
    try {
      await contract.methods
        .setTargetPriceBot(
          getWeiString(targetPrice, PRICE_MULTIPLIER),
          Math.floor((slippage ?? DEFAULT_SLIPPAGE) * SLIPPAGE_MULTIPLIER),
          Math.floor(numberOfTransactions),
          Math.floor(new Date(Date.now() + timeframeInHours * 60 * 60 * 1000).getTime() / 1000),
          Math.floor((volatility ?? DEFAULT_VOLATILITY) * VOLATILITY_MULTIPLIER),
        )
        .send({ from: account, value: getWeiString(bot.transactionFee * numberOfTransactions) })
      refresh()
      return true
    } catch (ex) {
      console.error(ex)
      return false
    }
  }

  const setVolume = async (
    totalVolume: number,
    decimals: number,
    timeframeInHours: number,
    numberOfTransactions: number,
    slippage: number | null,
    volatility: number | null,
  ) => {
    if (!address || !web3 || !account || !isAuthenticated || !bot) {
      return false
    }
    const contract = new web3.eth.Contract(botAbi as AbiItem[], address)
    try {
      await contract.methods
        .setVolumeBot(
          getWeiString(totalVolume, decimals),
          Math.floor((slippage ?? DEFAULT_SLIPPAGE) * SLIPPAGE_MULTIPLIER),
          Math.floor(numberOfTransactions),
          Math.floor(new Date(Date.now() + timeframeInHours * 60 * 60 * 1000).getTime() / 1000),
          Math.floor((volatility ?? DEFAULT_VOLATILITY) * VOLATILITY_MULTIPLIER),
        )
        .send({ from: account, value: getWeiString(bot.transactionFee * numberOfTransactions) })
      refresh()
      return true
    } catch (ex) {
      console.error(ex)
      return false
    }
  }

  const switchBotStatus = async () => {
    if (!bot || !address || !web3 || !account || !isAuthenticated) {
      setIsLoading(false)
      return false
    }
    const contract = new web3.eth.Contract(botAbi as AbiItem[], address)
    try {
      if (bot.isEnabled) {
        await contract.methods.disable().send({ from: account })
      } else {
        await contract.methods.enable().send({ from: account })
      }
      refresh()
      return true
    } catch (ex) {
      console.error(ex)
      return false
    }
  }

  useEffect(() => {
    refresh()
    const interval = setInterval(() => refresh(true), REFRESH_INTERVAL)
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line
  }, [address, web3, account, isAuthenticated])

  return { bot, setTargetPrice, setVolume, switchBotStatus, isLoading }
}
