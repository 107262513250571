import React, { useState } from 'react'
import { Box, Heading, Table, Tbody, Td, Tr, Badge, Button } from '@chakra-ui/react'
import Moralis from 'moralis'
import { BotContract } from 'types/bot'
import Loader from 'components/Loader'
import { useOnboarding } from 'hooks/useOnboarding'

type Props = {
  bot: BotContract
}

const Subscription = ({ bot }: Props) => {
  const { signUpPrice, renewBot } = useOnboarding({ pairAddress: bot.pairAddress })
  const [isLoading, setIsLoading] = useState(false)
  const onButtonClick = async () => {
    setIsLoading(true)
    await renewBot(bot.address)
    setIsLoading(false)
  }
  return (
    <Box w="100%" bg="gray.800" borderRadius="lg" p={8} mt={3}>
      <Heading fontSize="2xl" mb={2}>
        Subscription
      </Heading>
      <>
        <Table size="sm">
          <Tbody>
            <Tr>
              <Td>Status</Td>
              <Td>
                {bot.expiration * 1000 > Date.now() ? (
                  <Badge colorScheme="green" height="fit-content">
                    VALID
                  </Badge>
                ) : (
                  <Badge colorScheme="red" height="fit-content">
                    EXPIRED
                  </Badge>
                )}
              </Td>
            </Tr>
            <Tr>
              <Td>Expiration</Td>
              <Td fontWeight="bold">{new Date(bot.expiration * 1000).toUTCString()}</Td>
            </Tr>
            <Tr>
              <Td>Monthly fee</Td>
              <Td fontWeight="bold">
                {signUpPrice ? Moralis.Units.FromWei(signUpPrice, 18).toFixed(9) : '-'} BNB
              </Td>
            </Tr>
          </Tbody>
        </Table>
        {!isLoading ? (
          <Button onClick={onButtonClick} mt={3} size="sm" w="100%">
            Renew
          </Button>
        ) : (
          <Loader size="md" showText={false} />
        )}
      </>
    </Box>
  )
}

export default Subscription
