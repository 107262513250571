import React from 'react'
import { Flex, Box, Heading, Divider, SimpleGrid } from '@chakra-ui/react'
import LineChart from 'components/Chart/Chart'
import { PairToken } from 'types/pair'
import { BotContract } from 'types/bot'
import { linearToCandlestick } from 'utils/candlestick'
import TokenBalances from '../Dashboard/components/TokenBalances'
import TargetSelection from '../Dashboard/components/TargetSelection'
import BotStatus from '../Dashboard/components/BotStatus'
import PriceHeader from '../Dashboard/components/PriceHeader'

const Demo = () => {
  const pairTokens: PairToken[] = [
    {
      address: '0x1',
      name: 'Pancake Token',
      symbol: 'CAKE',
      decimals: 18,
      reserve: 4900000,
      price: 14.5,
      accountBalance: 500000,
    },
    {
      address: '0x2',
      name: 'Wrapped BNB',
      symbol: 'WBNB',
      decimals: 18,
      reserve: 125000,
      price: 550.25,
      accountBalance: 10000,
    },
  ]
  const startTime = 1640908800
  const historicalPrices = [
    {
      data: linearToCandlestick([
        // eslint-disable-next-line
        // @ts-ignore:next-line
        ...[...Array(100).keys()].map(num => ({
          time: startTime + num * 60 * 1000,
          value: Math.random(),
        })),
        { time: startTime + 101 * 60 * 1000, value: 0.0255102 },
      ]),
      options: { title: `${pairTokens[1].symbol}/${pairTokens[0].symbol}` },
    },
  ]
  const price = pairTokens[1].reserve / pairTokens[0].reserve
  const bot: BotContract = {
    address: '',
    isEnabled: true,
    mod: 1,
    pairAddress: '0x11',
    chainId: '0x38',
    token0Address: '0x1',
    token1Address: '0x2',
    targetTokenAddress: '0x1',
    targetPrice: 0.0265,
    totalVolume: 20000,
    transactionsTotal: 20,
    transactionsRemaining: 15,
    transactionsStartTime: (Date.now() - 2 * 24 * 60 * 60 * 1000) / 1000,
    transactionsEndTime: (Date.now() + 2 * 24 * 60 * 60 * 1000) / 1000,
    fees: 0.01,
    expiration: (Date.now() + 15 * 24 * 60 * 60 * 1000) / 1000,
    transactionFee: 0.005,
  }

  const maxTargetPrice = 0.03252089
  const minTargetPrice = 0.0153407

  const setTargetPrice = () => {}
  const setVolume = () => {}
  const switchBotStatus = () => {}

  return (
    <Flex w="100%" h="100%" p={4} flexDir="column">
      <Flex flexDir="column" w="100%" maxWidth="1200px" alignSelf="center">
        <Flex w="100%" flexDir="row" justifyContent="space-between">
          <Box w="100%" py={2}>
            <Box bg="gray.800" borderRadius="lg" p={8}>
              <PriceHeader price={price} tokens={pairTokens} />
              <LineChart chartData={historicalPrices} height={400} />
            </Box>

            <SimpleGrid minChildWidth="300px" columns={2} spacing={4} mt={3}>
              <Box bg="gray.800" borderRadius="lg" p={8}>
                <Heading fontSize="2xl">Pool Balance</Heading>
                <TokenBalances tokens={pairTokens} />
              </Box>
              <Box bg="gray.800" borderRadius="lg" p={8}>
                <Heading fontSize="2xl">Wallet Balance</Heading>
                <TokenBalances tokens={pairTokens} isAccount />
              </Box>
            </SimpleGrid>

            <Box bg="gray.800" borderRadius="lg" p={8} mt={3}>
              <BotStatus bot={bot} tokenSymbol={pairTokens[1].symbol} onSwitch={switchBotStatus} />
              <Divider my={6} />
              <TargetSelection
                maxTargetPrice={maxTargetPrice}
                minTargetPrice={minTargetPrice}
                pairTokens={pairTokens}
                bot={bot}
                onTargetPriceUpdate={setTargetPrice}
                onVolumeUpdate={setVolume}
              />
            </Box>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Demo
