import { LineChartValue } from 'types/chart'

export const linearToCandlestick = (linearValues: LineChartValue[]) => {
  return linearValues.map((linearValue, index) => {
    const currentValue = linearValue.value
    if (index === 0) {
      return {
        time: linearValue.time,
        value: currentValue,
        open: currentValue,
        high: currentValue,
        low: currentValue,
        close: currentValue,
      }
    }

    const lastValue = linearValues[index - 1].value
    return {
      time: linearValue.time,
      value: currentValue,
      open: lastValue,
      high: Math.max(lastValue, currentValue),
      low: Math.min(lastValue, currentValue),
      close: currentValue,
    }
  })
}
