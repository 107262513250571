import React from 'react'
import { Box, SimpleGrid, Text } from '@chakra-ui/react'
import FormattedNumber from 'components/FormattedNumber'
import { PairToken } from 'types/pair'

type Props = {
  tokens: PairToken[]
  isAccount?: boolean
}

const TokenBalances = ({ tokens, isAccount }: Props) => {
  const totalAllocation = isAccount
    ? tokens.reduce((acc, token) => token.price * (token.accountBalance ?? 0) + acc, 0)
    : tokens.reduce((acc, token) => token.price * token.reserve + acc, 0)

  const columns = [
    {
      title: 'Amount',
      key: 'amount',
      render: (item: PairToken) =>
        isAccount ? (
          <FormattedNumber value={item.accountBalance ?? 0} decimals={2} smallDecimals={4} />
        ) : (
          <FormattedNumber value={item.reserve ?? 0} decimals={2} smallDecimals={4} />
        ),
    },
    {
      title: 'Price',
      key: 'price',
      render: (item: PairToken) => (
        <>
          $<FormattedNumber value={item.price ?? 0} decimals={2} smallDecimals={4} />
        </>
      ),
    },
    {
      title: 'Share',
      key: 'share',
      render: (item: PairToken) => {
        const share = isAccount
          ? ((item.price * (item.accountBalance ?? 0)) / totalAllocation) * 100
          : ((item.price * item.reserve) / totalAllocation) * 100
        return Number.isNaN(share) ? '-' : `${share.toFixed(2)}%`
      },
    },
  ]

  return (
    <Box p={2} mt={3}>
      <Text fontSize="xl">{tokens[0].symbol.toUpperCase()}</Text>
      <SimpleGrid columns={3} spacingX={5}>
        <Box>
          <Text fontSize="xl" fontWeight="extrabold">
            {columns[0].render(tokens[0])}
          </Text>
          <Text fontSize="sm">{columns[0].title}</Text>
        </Box>
        <Box>
          <Text fontSize="xl" fontWeight="extrabold">
            {columns[1].render(tokens[0])}
          </Text>
          <Text fontSize="sm">{columns[1].title}</Text>
        </Box>
        <Box>
          <Text Text fontSize="xl" fontWeight="extrabold">
            {columns[2].render(tokens[0])}
          </Text>
          <Text fontSize="sm">{columns[2].title}</Text>
        </Box>
      </SimpleGrid>
      <Text mt={8} fontSize="xl">
        {tokens[1].symbol.toUpperCase()}
      </Text>
      <SimpleGrid columns={3} spacingX="20px">
        <Box>
          <Text fontSize="xl" fontWeight="extrabold">
            {columns[0].render(tokens[1])}
          </Text>
          <Text fontSize="sm">{columns[0].title}</Text>
        </Box>
        <Box>
          <Text fontSize="xl" fontWeight="extrabold">
            {columns[1].render(tokens[1])}
          </Text>
          <Text fontSize="sm">{columns[1].title}</Text>
        </Box>
        <Box>
          <Text fontSize="xl" fontWeight="extrabold">
            {columns[2].render(tokens[1])}
          </Text>
          <Text fontSize="sm">{columns[2].title}</Text>
        </Box>
      </SimpleGrid>
    </Box>
  )
}

export default TokenBalances
