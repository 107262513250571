import React, { useState, useEffect } from 'react'
import { Container, Box, Center, Spinner, Text, Button } from '@chakra-ui/react'
import { useClientBots } from 'hooks/useClientBots'
import { useTokenApproval } from 'hooks/useTokenApproval'
import { MoralisToken } from 'types/moralis/token'
import ErrorMessage from 'components/ErrorMessage'

type Props = {
  pairTokens: MoralisToken[]
  onSuccess: () => void
}

const Approval = ({ pairTokens, onSuccess }: Props) => {
  const { clientBots, isLoading } = useClientBots()
  const { approveToken } = useTokenApproval()
  const [isToken0Approved, setIsToken0Approved] = useState(false)
  const [isToken1Approved, setIsToken1Approved] = useState(false)
  const [botContractAddress, setBotContractAddress] = useState<string | null>(null)
  const [isApprovalLoading, setIsApprovalLoading] = useState(false)

  useEffect(() => {
    if (!clientBots) {
      return
    }
    setBotContractAddress(clientBots[clientBots.length - 1])
  }, [clientBots])

  const approve = async (address: string) => {
    if (!botContractAddress) {
      return
    }
    setIsApprovalLoading(true)
    const result = await approveToken(botContractAddress, address)
    setIsApprovalLoading(false)
    if (!result) {
      return
    }
    if (address === pairTokens[0].address) {
      setIsToken0Approved(true)
    } else {
      setIsToken1Approved(true)
    }
  }

  const submit = () => {
    if (isToken0Approved && isToken1Approved) {
      onSuccess()
    }
  }

  return (
    <Container align="center" py={12}>
      <Text fontSize="3xl" py={2} fontWeight="bold">
        Approval
      </Text>
      <Text fontSize="xl">Approve token spending</Text>
      {!isLoading && !isApprovalLoading && botContractAddress && (
        <>
          <Box pt={12}>
            <Button
              onClick={() => approve(pairTokens[0].address)}
              colorScheme={isToken0Approved ? 'green' : 'blue'}
              disabled={isToken0Approved}
              fontSize="xl"
              w="100%"
              p={6}
            >
              Approve {pairTokens[0].symbol}
            </Button>
          </Box>
          <Box pt={4}>
            <Button
              onClick={() => approve(pairTokens[1].address)}
              colorScheme={isToken1Approved ? 'green' : 'blue'}
              disabled={isToken1Approved}
              fontSize="xl"
              w="100%"
              p={6}
            >
              Approve {pairTokens[1].symbol}
            </Button>
          </Box>
          <Box pt={12}>
            <Button
              onClick={submit}
              colorScheme="blue"
              disabled={!isToken0Approved || !isToken1Approved}
              fontSize="xl"
              p={6}
            >
              Continue
            </Button>
          </Box>
        </>
      )}
      {(isLoading || isApprovalLoading) && (
        <Center py={12}>
          <Spinner size="xl" />
        </Center>
      )}
      {!isLoading && !botContractAddress && <ErrorMessage message="An error has occurred" />}
    </Container>
  )
}

export default Approval
