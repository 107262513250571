import React, { useState } from 'react'
import { Box } from '@chakra-ui/react'
import { MoralisToken } from 'types/moralis/token'
import Steps from '../../components/Steps'
import PairInformation from './components/PairInformation'
import Payment from './components/Payment'
import Approval from './components/Approval'
import Success from './components/Success'

const Onboarding = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [pairAddress, setPairAddress] = useState<string | null>(null)
  const [pairTokens, setPairTokens] = useState<MoralisToken[] | null>(null)
  const [selectedTokenAddress, setSelectedTokenAddress] = useState<string | null>(null)

  const nextStep = () => {
    setCurrentStep(currentStep + 1)
  }

  const onPairInformationSuccess = (pair: string, tokens: MoralisToken[], token: string) => {
    setPairAddress(pair)
    setPairTokens(tokens)
    setSelectedTokenAddress(token)
    nextStep()
  }

  return (
    <Box py={10}>
      <Steps
        currentStep={currentStep}
        steps={[
          {
            number: 1,
            label: 'Pair Information',
            component: <PairInformation onSuccess={onPairInformationSuccess} />,
          },
          {
            number: 2,
            label: 'Payment',
            component: pairAddress && pairTokens && selectedTokenAddress && (
              <Payment
                onSuccess={nextStep}
                pairAddress={pairAddress}
                pairTokens={pairTokens}
                selectedTokenAddress={selectedTokenAddress}
              />
            ),
          },
          {
            number: 3,
            label: 'Approval',
            component: pairTokens && <Approval onSuccess={nextStep} pairTokens={pairTokens} />,
          },
          { number: 4, label: 'Success', component: <Success /> },
        ]}
      />
    </Box>
  )
}

export default Onboarding
