import React, { useState, useEffect } from 'react'
import { useMoralis } from 'react-moralis'
import { contracts } from 'data/contracts'
import onboardingAbi from 'data/abi/Onboarding.json'
import { AbiItem } from 'web3-utils'
import { getWeiString } from 'utils/formatters'

type Params = {
  pairAddress: string
}

const DISCOUNT_MULTIPLIER = 100

export const useOnboarding = ({ pairAddress }: Params) => {
  const { account, web3, isAuthenticated } = useMoralis()
  const [signUpPrice, setSignUpPrice] = useState<string | null>(null)

  useEffect(() => {
    if (!web3 || !account || !isAuthenticated) {
      return
    }
    ;(async () => {
      const contract = new web3.eth.Contract(
        onboardingAbi as AbiItem[],
        contracts.onboarding.address,
      )
      const price = await contract.methods
        .signUpPrice()
        .call()
        .catch(() => null)
      const discount = await contract.methods
        .getDiscount(account, pairAddress)
        .call()
        .catch(() => null)
      if (price && discount) {
        const discountValue = discount / DISCOUNT_MULTIPLIER / 100
        setSignUpPrice(getWeiString(price * (1 - discountValue), 0))
      }
    })()
  }, [web3, account, isAuthenticated, pairAddress])

  const buyBot = async (token0Address: string, token1Address: string, targetIndex: 0 | 1) => {
    if (!web3 || !account || !isAuthenticated) {
      return false
    }
    const contract = new web3.eth.Contract(onboardingAbi as AbiItem[], contracts.onboarding.address)
    const bot = await contract.methods
      .buyBot(
        pairAddress,
        token0Address,
        token1Address,
        targetIndex === 0 ? token1Address : token0Address,
      )
      .send({ from: account, value: signUpPrice })
      .catch(() => null)

    if (bot) {
      return bot
    }

    return null
  }

  const renewBot = async (botAddress: string) => {
    if (!web3 || !account || !isAuthenticated) {
      return false
    }
    const contract = new web3.eth.Contract(onboardingAbi as AbiItem[], contracts.onboarding.address)
    try {
      await contract.methods.renew(botAddress).send({ from: account, value: signUpPrice })
      window.location.reload()
      return true
    } catch (ex) {
      console.error(ex)
      return false
    }
  }

  return { signUpPrice, buyBot, renewBot }
}
