import React from 'react'
import { IconButton, Flex, Box, useColorModeValue, FlexProps } from '@chakra-ui/react'

interface MobileProps extends FlexProps {
  onOpen: () => void
}

const MobileNavbar = ({ onOpen }: MobileProps) => {
  return (
    <>
      <Flex
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 24 }}
        height="20"
        alignItems="center"
        bg={useColorModeValue('white', 'gray.900')}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
        justifyContent="flex-start"
        display={{ base: 'flex', md: 'none' }}
      >
        <IconButton onClick={onOpen} aria-label="open menu">
          <Box className="fa fa-bars" />
        </IconButton>
      </Flex>
    </>
  )
}

export default MobileNavbar
