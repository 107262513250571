import React from 'react'
import {
  Box,
  Heading,
  Text,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { PairSwapTransaction } from 'types/pair'

type Props = {
  transactions: PairSwapTransaction[]
  secondaryTokenSymbol: string
}

const History = ({ transactions, secondaryTokenSymbol }: Props) => {
  return (
    <Box w="100%" bg="gray.800" borderRadius="lg" p={8} mt={3}>
      <Heading fontSize="2xl" mb={3}>
        History
      </Heading>
      <Text mb={3}>Your transactions are highlighted</Text>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Type</Th>
              <Th>Time</Th>
              <Th>Price ({secondaryTokenSymbol})</Th>
              <Th>Amount</Th>
              <Th>Sum ({secondaryTokenSymbol})</Th>
            </Tr>
          </Thead>
          <Tbody>
            {transactions.map(tx => (
              <Tr
                textColor={tx.isBuy ? '#60CE99' : '#BC6B6B'}
                bg={tx.isAccountTransaction ? 'rgb(255,255,255,0.075)' : undefined}
                fontWeight={tx.isAccountTransaction ? 'bold' : undefined}
              >
                <Td>{tx.isBuy ? 'Buy' : 'Sell'}</Td>
                <Td>{new Date(tx.timestamp).toUTCString()}</Td>
                <Td>{tx.price.toFixed(6)}</Td>
                <Td>{tx.amount.toFixed(6)}</Td>
                <Td>{(tx.amount * tx.price).toFixed(6)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default History
