import React, { useState } from 'react'
import {
  Box,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Divider,
  Button,
  Text,
} from '@chakra-ui/react'
import { PairToken } from 'types/pair'
import { BotContract } from 'types/bot'
import TargetPrice from './TargetPrice'
import TargetVolume from './TargetVolume'

type Props = {
  maxTargetPrice: number
  minTargetPrice: number
  pairTokens: PairToken[]
  bot: BotContract
  onTargetPriceUpdate: (
    targetPrice: number,
    timeframe: number,
    numberOfTransactions: number,
    slippage: number | null,
    volatility: number | null,
  ) => void
  onVolumeUpdate: (
    totalVolume: number,
    decimals: number,
    timeframe: number,
    numberOfTransactions: number,
    slippage: number | null,
    volatility: number | null,
  ) => void
}

const TargetSelection = ({
  maxTargetPrice,
  minTargetPrice,
  pairTokens,
  bot,
  onTargetPriceUpdate,
  onVolumeUpdate,
}: Props) => {
  const [tabSelected, setTabSelected] = useState(0)
  return (
    <Box>
      <Tabs variant="unstyled" mb={2}>
        <TabList>
          <Heading mt={3} mr={6} fontSize="2xl">
            Target Settings
          </Heading>
          <Tab onClick={() => setTabSelected(0)}>
            <Button bg={tabSelected === 0 ? 'grey' : undefined}>
              <Text fontWeight="bold">Price</Text>
            </Button>
          </Tab>
          <Tab onClick={() => setTabSelected(1)}>
            <Button bg={tabSelected === 1 ? 'grey' : undefined}>
              <Text fontWeight="bold">Volume</Text>
            </Button>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}>
            <TargetPrice
              onUpdate={onTargetPriceUpdate}
              maxTargetPrice={maxTargetPrice}
              minTargetPrice={minTargetPrice}
              tokens={pairTokens}
              transactionFee={bot.transactionFee}
            />
          </TabPanel>
          <TabPanel p={0}>
            <TargetVolume
              onUpdate={onVolumeUpdate}
              tokens={pairTokens}
              transactionFee={bot.transactionFee}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default TargetSelection
