import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Container, Text, Button } from '@chakra-ui/react'

const Success = () => {
  return (
    <Container align="center" py={12}>
      <Text fontSize="3xl" py={2} fontWeight="bold">
        You&apos;re all set!
      </Text>
      <Text fontSize="xl" mb={8}>
        Your bot is ready to use
      </Text>
      <NavLink to="/dashboard">
        <Button colorScheme="blue" fontSize="xl" p={6}>
          Go to Dashboard
        </Button>
      </NavLink>
    </Container>
  )
}

export default Success
