import React, { useEffect, ChangeEvent } from 'react'
import { Box, Flex, Select } from '@chakra-ui/react'
import ErrorMessage from 'components/ErrorMessage'
import { useClientBots } from 'hooks/useClientBots'
import Loader from 'components/Loader'

type Props = {
  selectedBotAddress: string
  onBotChange: (address: string) => void
}

const BotSelect = ({ selectedBotAddress, onBotChange }: Props) => {
  const { clientBots, isLoading } = useClientBots()

  useEffect(() => {
    if (clientBots && clientBots.length > 0) {
      onBotChange(clientBots[0])
    }
  }, [clientBots, onBotChange])

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onBotChange(event.target.value)
  }

  return (
    <Box bg="gray.900" p={4}>
      {!isLoading && clientBots && clientBots.length > 0 && (
        <Flex alignItems="center" gap={8}>
          <Select w={200} onChange={onChange}>
            {clientBots.map((botAddress, i) => (
              <option key={botAddress} value={botAddress}>
                BOT {i + 1}
              </option>
            ))}
          </Select>
          <Box opacity={0.75}>{selectedBotAddress}</Box>
        </Flex>
      )}
      {isLoading && <Loader />}
      {!isLoading && !clientBots && <ErrorMessage message="An error has occurred" />}
      {!isLoading && clientBots && clientBots.length === 0 && (
        <ErrorMessage message="You have no bots. Set up a new bot in Onboarding page." />
      )}
    </Box>
  )
}

export default BotSelect
