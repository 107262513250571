import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { PairToken } from 'types/pair'

type Props = {
  price: number
  tokens: PairToken[]
}

const PriceHeader = ({ price, tokens }: Props) => {
  const usdPrice = price * tokens[1].price
  return (
    <Flex mb={6}>
      <Box flex={1}>
        <Box fontSize="3xl" fontWeight="bold">
          {tokens[0].symbol} / {tokens[1].symbol}
        </Box>
        <Box>{tokens[0].name}</Box>
      </Box>
      <Box textAlign="right">
        <Box fontWeight="bold" fontSize="3xl">
          {price.toFixed(8)} {tokens[1].symbol}
        </Box>
        <Box>${usdPrice.toFixed(8)}</Box>
      </Box>
    </Flex>
  )
}

export default PriceHeader
