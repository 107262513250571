import Moralis from 'moralis'
import pairAbi from 'data/abi/PancakePair.json'
import { ChainId } from 'types/moralis'
import { MoralisToken } from 'types/moralis/token'
import { TargetPriceStats } from 'types/pair'

export const runPairFunction = (address: string, chainId: ChainId, functionName: string) => {
  return Moralis.Web3API.native.runContractFunction({
    address,
    chain: chainId,
    function_name: functionName,
    abi: pairAbi,
  } as any)
}

export const getPairTokensMetadata = async (
  pairAddress: string,
  chainId: ChainId,
): Promise<MoralisToken[] | null> => {
  try {
    const token0Address = await runPairFunction(pairAddress, chainId, 'token0')
    const token1Address = await runPairFunction(pairAddress, chainId, 'token1')
    const tokenMetadata = await Moralis.Web3API.token.getTokenMetadata({
      chain: chainId,
      addresses: [token0Address, token1Address],
    })
    return tokenMetadata
  } catch {
    return null
  }
}

export const getTargetPriceStats = (
  targetPrice: number,
  numberOfTransactions: number,
  reserve0: number,
  reserve1: number,
): TargetPriceStats => {
  const currentPrice = reserve1 / reserve0
  const amountToBuy = (targetPrice * reserve0 - reserve1) / (currentPrice + targetPrice)
  if (targetPrice > currentPrice) {
    const token1Needed = amountToBuy * currentPrice
    return {
      token0Needed: 0,
      token1Needed,
      token0NeededPerTx: 0,
      token1NeededPerTx: token1Needed / numberOfTransactions,
    }
  }
  const token0Needed = amountToBuy * -1
  return {
    token0Needed,
    token1Needed: 0,
    token0NeededPerTx: token0Needed / numberOfTransactions,
    token1NeededPerTx: 0,
  }
}
