import React from 'react'
import { Flex, Box, Heading, SimpleGrid, Divider } from '@chakra-ui/react'
import { useMoralis } from 'react-moralis'
import { usePair } from 'hooks/usePair'
import { useBot } from 'hooks/useBot'
import LineChart from 'components/Chart/Chart'
import ErrorMessage from 'components/ErrorMessage'
import Loader from 'components/Loader'
import { contracts } from 'data/contracts'
import { networkConfigs } from 'data/networks'
import TokenBalances from './TokenBalances'
import TargetSelection from './TargetSelection'
import BotStatus from './BotStatus'
import PriceHeader from './PriceHeader'
import Subscription from './Subscription'
import History from './History'

type Props = {
  botAddress: string
}

const BotPage = ({ botAddress }: Props) => {
  const { account, chainId, isAuthenticated } = useMoralis()
  const {
    bot,
    setTargetPrice,
    setVolume,
    switchBotStatus,
    isLoading: isBotLoading,
  } = useBot({
    address: botAddress,
  })
  const {
    pairTokens,
    historicalPrices,
    historicalTransactions,
    price,
    maxTargetPrice,
    minTargetPrice,
    isLoading: isPairLoading,
    isError,
  } = usePair({
    address: bot?.pairAddress ?? null,
    chainId: bot?.chainId ?? null,
    targetTokenAddress: bot?.targetTokenAddress ?? null,
  })

  const isLoading = isPairLoading || isBotLoading

  if (chainId !== contracts.onboarding.chainId) {
    return (
      <ErrorMessage
        message={`Please switch to ${
          networkConfigs[contracts.onboarding.chainId].chainName
        } network and try again`}
      />
    )
  }

  if (!account || !isAuthenticated) {
    return <ErrorMessage message="Please connect to your wallet" />
  }

  if (isLoading) {
    return <Loader />
  }

  if (isError) {
    return <ErrorMessage message="An error has occurred while getting data" />
  }

  if (
    !pairTokens ||
    !price ||
    !historicalPrices ||
    !historicalTransactions ||
    !bot ||
    maxTargetPrice === null ||
    minTargetPrice === null
  ) {
    return <Loader />
  }

  return (
    <Flex w="100%" h="100%" p={4} flexDir="column">
      <Flex flexDir="column" w="100%" maxWidth="1200px" alignSelf="center">
        <Flex w="100%" flexDir="row" justifyContent="space-between">
          <Box w="100%" py={2}>
            <Box bg="gray.800" borderRadius="lg" p={8}>
              <PriceHeader price={price} tokens={pairTokens} />
              <LineChart chartData={historicalPrices} height={400} />
            </Box>

            <SimpleGrid minChildWidth="300px" columns={2} spacing={4} mt={3}>
              <Box bg="gray.800" borderRadius="lg" p={8}>
                <Heading fontSize="2xl">Pool Balance</Heading>
                <TokenBalances tokens={pairTokens} />
              </Box>
              <Box bg="gray.800" borderRadius="lg" p={8}>
                <Heading fontSize="2xl">Wallet Balance</Heading>
                <TokenBalances tokens={pairTokens} isAccount />
              </Box>
            </SimpleGrid>

            <Box bg="gray.800" borderRadius="lg" p={8} mt={3}>
              <BotStatus bot={bot} tokenSymbol={pairTokens[1].symbol} onSwitch={switchBotStatus} />
              <Divider my={6} />
              <TargetSelection
                maxTargetPrice={maxTargetPrice}
                minTargetPrice={minTargetPrice}
                pairTokens={pairTokens}
                bot={bot}
                onTargetPriceUpdate={setTargetPrice}
                onVolumeUpdate={setVolume}
              />
            </Box>
            <Subscription bot={bot} />
            <History
              transactions={historicalTransactions}
              secondaryTokenSymbol={pairTokens[1].symbol}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default BotPage
