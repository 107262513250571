import React from 'react'

type Props = {
  value: number
  decimals: number
  smallDecimals: number
}

const FormattedNumber = ({ value, decimals, smallDecimals }: Props) => {
  if (value < 10 ** 3) {
    return <>{value.toFixed(smallDecimals)}</>
  }
  if (value < 10 ** 6) {
    return <>{`${(value / 10 ** 3).toFixed(decimals)}k`}</>
  }
  if (value < 10 ** 9) {
    return <>{`${(value / 10 ** 6).toFixed(decimals)}M`}</>
  }
  return <>{`${(value / 10 ** 9).toFixed(decimals)}T`}</>
}

export default FormattedNumber
