import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Divider, Container, Button, Center, SimpleGrid } from '@chakra-ui/react'
import IconText from './IconText'

const Home = () => {
  return (
    <Container maxW="container.xl" py={6}>
      <Box py={24}>
        <Box fontSize="5xl" textAlign="center" fontWeight="bold" mb={5}>
          Control Your Token
        </Box>
        <Box fontSize="2xl" textAlign="center">
          AI-powered bot that enables you to have a full control over the price and volume of your
          token
        </Box>
        <Center mt={12}>
          <Link to="/onboarding">
            <Button fontSize="2xl" py={6} px={12} mx={3} fontWeight="bold" colorScheme="blue">
              Start
            </Button>
          </Link>
          <Link to="/demo">
            <Button fontSize="2xl" py={6} px={12} mx={3} fontWeight="bold">
              Demo
            </Button>
          </Link>
        </Center>
      </Box>
      <Box p={12} bg="gray.800" borderRadius="xl">
        <Box fontSize="4xl" textAlign="center" fontWeight="bold" mb={8}>
          HOW TO START?
        </Box>
        <SimpleGrid columns={[1, null, 3]} gap={24}>
          <IconText icon="fas fa-plus fa-4x" header="Onboarding">
            Provide your pair address and token, pay the initial fee, and approve tokens
          </IconText>
          <IconText icon="fas fa-chart-line fa-4x" header="Set the Targets">
            Set the target price or volume in the Dashboard
          </IconText>
          <IconText icon="fas fa-robot fa-4x" header="Enjoy the Result">
            Bot will do all the work, you can change the strategy or stop it anytime
          </IconText>
        </SimpleGrid>
      </Box>
      <Box py={24}>
        <Box fontSize="3xl" textAlign="center" fontWeight="bold" mb={8}>
          CHECK OUT THE DEMO FOR FREE
        </Box>
        <Center>
          <Link to="/demo">
            <Button fontSize="2xl" py={6} px={12} fontWeight="bold" colorScheme="blue">
              See the Demo
            </Button>
          </Link>
        </Center>
      </Box>
    </Container>
  )
}

export default Home
