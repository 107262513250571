import React from 'react'
import { Flex, Box, Spinner, Heading } from '@chakra-ui/react'

type Props = {
  size?: string
  showText?: boolean
}

const Loader = ({ size, showText }: Props) => {
  return (
    <Flex flex={1}>
      <Box w="100%" textAlign="center" p={6}>
        <Spinner my={3} size={size ?? 'xl'} />
        {showText !== false && <Heading fontSize="3xl">Loading</Heading>}
      </Box>
    </Flex>
  )
}

export default Loader
