import React, { useState, useEffect, ChangeEvent } from 'react'
import { Button, Input, Box, Text, SimpleGrid } from '@chakra-ui/react'
import { useMoralis } from 'react-moralis'
import Loader from 'components/Loader'
import InputLabel from 'components/InputLabel'
import { getTargetPriceStats } from 'utils/pair'
import { PairToken, TargetPriceStats } from 'types/pair'
import { networkConfigs } from 'data/networks'

type Props = {
  maxTargetPrice: number
  minTargetPrice: number
  tokens: PairToken[]
  transactionFee: number
  onUpdate: (
    targetPrice: number,
    timeframe: number,
    numberOfTransactions: number,
    slippage: number | null,
    volatility: number | null,
  ) => void
}

const TRANSACTIONS_PER_HOUR = 12

const TargetPrice = ({
  maxTargetPrice,
  minTargetPrice,
  tokens,
  transactionFee,
  onUpdate,
}: Props) => {
  const { chainId } = useMoralis()
  const [targetPrice, setTargetPrice] = useState(0)
  const [timeframe, setTimeframe] = useState(0)
  const [numberOfTransactions, setNumberOfTransactions] = useState(0)
  const [slippage, setSlippage] = useState<number | null>(null)
  const [volatility, setVolatility] = useState<number | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [stats, setStats] = useState<TargetPriceStats | null>(null)

  useEffect(() => {
    setErrorMessage(null)
    setStats(null)
    setIsValid(true)
    if (
      Number.isNaN(targetPrice) ||
      Number.isNaN(timeframe) ||
      Number.isNaN(numberOfTransactions) ||
      targetPrice <= 0 ||
      timeframe <= 0 ||
      numberOfTransactions <= 0 ||
      (slippage !== null && slippage <= 0)
    ) {
      setIsValid(false)
      return
    }
    if (timeframe * TRANSACTIONS_PER_HOUR < numberOfTransactions) {
      setIsValid(false)
      setErrorMessage('Too many transactions for a given timeframe.')
      return
    }
    if (targetPrice > maxTargetPrice) {
      setIsValid(false)
      setErrorMessage('Target price is too high.')
      return
    }
    if (targetPrice < minTargetPrice) {
      setIsValid(false)
      setErrorMessage('Target price is too low.')
      return
    }
    setStats(
      getTargetPriceStats(targetPrice, numberOfTransactions, tokens[0].reserve, tokens[1].reserve),
    )
  }, [
    targetPrice,
    timeframe,
    numberOfTransactions,
    slippage,
    tokens,
    maxTargetPrice,
    minTargetPrice,
  ])

  const handleSubmit = async () => {
    setIsLoading(true)
    await onUpdate(
      targetPrice,
      timeframe,
      numberOfTransactions,
      !Number.isNaN(slippage) ? slippage : null,
      !Number.isNaN(volatility) ? volatility : null,
    )
    setIsLoading(false)
  }

  return (
    <Box>
      <Box my={4} fontWeight="bold">
        <Box>
          Max: {maxTargetPrice.toFixed(8)} {tokens[1].symbol}
        </Box>
        <Box>
          Min: {minTargetPrice.toFixed(8)} {tokens[1].symbol}
        </Box>
      </Box>
      <SimpleGrid minChildWidth="120px" columns={2} spacing={8}>
        <Box>
          <Box mb={4}>
            <InputLabel label="Price" sublabel={tokens[1].symbol} />
            <Input
              type="number"
              placeholder="0.0"
              onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
                setTargetPrice(parseFloat(changeEvent.target.value))
              }}
            />
          </Box>
          <Box mb={4}>
            <InputLabel label="Timeframe" sublabel="Hours" />
            <Input
              type="number"
              placeholder="0"
              onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
                setTimeframe(parseFloat(changeEvent.target.value))
              }}
            />
          </Box>
          <Box>
            <InputLabel label="Number of transactions" />
            <Input
              type="number"
              placeholder="0"
              onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
                setNumberOfTransactions(parseFloat(changeEvent.target.value))
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box mb={4}>
            <InputLabel label="Slippage" sublabel="%" />
            <Input
              type="number"
              placeholder="Optional"
              onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
                setSlippage(parseFloat(changeEvent.target.value))
              }}
            />
          </Box>
          <Box>
            <InputLabel label="Volatility" sublabel="%" />
            <Input
              type="number"
              placeholder="Optional"
              onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
                setVolatility(parseFloat(changeEvent.target.value))
              }}
            />
          </Box>
        </Box>
      </SimpleGrid>

      {stats && (
        <Box pt={6}>
          <Text>Tokens Needed</Text>
          <Box>
            <Text fontSize="xl" fontWeight="bold">
              {stats.token0Needed > 0 && (
                <>
                  {stats.token0Needed.toFixed(4)} {tokens[0].symbol}
                </>
              )}
            </Text>
            <Text fontSize="xl" fontWeight="bold">
              {stats.token1Needed > 0 && (
                <>
                  {stats.token1Needed.toFixed(4)} {tokens[1].symbol}
                </>
              )}
            </Text>
          </Box>
        </Box>
      )}

      {numberOfTransactions > 0 && (
        <Box pt={6}>
          <Text>Transaction fees</Text>
          <Box>
            <Text fontSize="xl" fontWeight="bold">
              {(numberOfTransactions * transactionFee).toFixed(4)}{' '}
              {chainId && networkConfigs[chainId].currencySymbol}
            </Text>
          </Box>
          <Text fontSize="xs" opacity={0.75}>
            Transaction fees are not refunded if you stop the bot before the end time
          </Text>
        </Box>
      )}

      {errorMessage && (
        <Box mt={6} fontSize="sm" fontWeight="bold">
          {errorMessage}
        </Box>
      )}
      {!isLoading ? (
        <Button mt={6} disabled={!isValid} size="sm" w="100%" onClick={handleSubmit}>
          Set Price
        </Button>
      ) : (
        <Loader size="md" showText={false} />
      )}
    </Box>
  )
}

export default TargetPrice
