import React, { useState } from 'react'
import { Box } from '@chakra-ui/react'
import { useMoralis } from 'react-moralis'
import ErrorMessage from 'components/ErrorMessage'

import BotSelect from './components/BotSelect'
import BotPage from './components/BotPage'

const Dashboard = () => {
  const { account, isAuthenticated } = useMoralis()
  const [selectedBotAddress, setSelectedBotAddress] = useState<string | null>(null)

  if (!account || !isAuthenticated) {
    return <ErrorMessage message="Please connect to your wallet" />
  }

  return (
    <Box>
      <BotSelect
        selectedBotAddress={selectedBotAddress ?? ''}
        onBotChange={setSelectedBotAddress}
      />
      {selectedBotAddress && <BotPage botAddress={selectedBotAddress} />}
    </Box>
  )
}

export default Dashboard
