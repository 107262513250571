import React, { useState } from 'react'
import { Box, Heading, Table, Button, Text, SimpleGrid } from '@chakra-ui/react'
import { BotContract } from 'types/bot'
import ErrorMessage from 'components/ErrorMessage'
import Loader from 'components/Loader'

type Props = {
  bot: BotContract
  tokenSymbol: string
  onSwitch: () => void
}

const BotStatus = ({ bot, tokenSymbol, onSwitch }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const onButtonClick = async () => {
    setIsLoading(true)
    await onSwitch()
    setIsLoading(false)
  }
  return (
    <Box>
      {bot.mod === 0 ? (
        <>
          <Heading fontSize="2xl">Bot Status</Heading>
          <ErrorMessage message="Target is not set" />
        </>
      ) : (
        <>
          <SimpleGrid minChildWidth="300px" columns={2} spacing={4}>
            <Box>
              <Heading fontSize="2xl">Bot Status</Heading>
              {bot.isEnabled && bot.transactionsEndTime * 1000 > Date.now() ? (
                <Text fontSize="3xl" fontWeight="bold" color="#60CE99">
                  RUNNING
                </Text>
              ) : (
                <Text fontSize="4xl" fontWeight="bold" color="#ef5350">
                  STOPPED
                </Text>
              )}

              <Text fontSize="lg" fontWeight="bold">
                {bot.transactionsTotal - bot.transactionsRemaining} / {bot.transactionsTotal}{' '}
                transactions
              </Text>
            </Box>
            <Box>
              <>
                <Text fontSize="14px" fontWeight="400">
                  {bot.mod === 1 ? 'Price Target' : 'Volume Target'}
                </Text>
                <Text fontSize="24px" fontWeight="700">
                  {bot.mod === 1 ? bot.targetPrice : bot.totalVolume} {tokenSymbol}
                </Text>
              </>
              <>
                <Text pt="10px" fontSize="14px" fontWeight="400">
                  End Time
                </Text>
                <Text fontSize="24px" fontWeight="700">
                  {new Date(bot.transactionsEndTime * 1000).toUTCString()}
                </Text>
              </>
            </Box>
          </SimpleGrid>
          <Table size="sm"></Table>
          {!isLoading ? (
            <Button maxW="200px" onClick={onButtonClick} mt={3} size="sm" w="100%">
              {bot.isEnabled ? 'Disable Bot' : 'Enable Bot'}
            </Button>
          ) : (
            <Loader size="md" showText={false} />
          )}
        </>
      )}
    </Box>
  )
}

export default BotStatus
