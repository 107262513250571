import React, { useState, useEffect } from 'react'
import Moralis from 'moralis'
import { useMoralis } from 'react-moralis'
import { ChartData, LineChartValue } from 'types/chart'
import { PairSwapTransaction, PairToken } from 'types/pair'
import { ChainId } from 'types/moralis'
import { getPairTokensMetadata } from 'utils/pair'
import pancakePairSwapAbi from 'data/abi/PancakePairSwap.json'
import pancakePairSyncAbi from 'data/abi/PancakePairSync.json'
import { events } from 'data/events'
import { linearToCandlestick } from 'utils/candlestick'

type Props = {
  address: string | null
  chainId: ChainId | null
  targetTokenAddress: string | null
}

const REFRESH_INTERVAL = 60000
const NUMBER_OF_EVENTS = 99

export const usePair = ({ address, chainId, targetTokenAddress }: Props) => {
  const { account, isAuthenticated } = useMoralis()
  const [pairTokens, setPairTokens] = useState<PairToken[] | null>(null)
  const [historicalPrices, setHistoricalPrices] = useState<ChartData[] | null>(null)
  const [historicalTransactions, setHistoricalTransactions] = useState<
    PairSwapTransaction[] | null
  >(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [price, setPrice] = useState<number | null>(null)
  const [maxTargetPrice, setMaxTargetPrice] = useState<number | null>(null)
  const [minTargetPrice, setMinTargetPrice] = useState<number | null>(null)

  const getAccountTokenBalance = (tokenAddress: string, accountTokens) => {
    const accountTokenBalance = accountTokens.find(
      token => token.token_address.toLowerCase() === tokenAddress.toLowerCase(),
    )?.balance
    return accountTokenBalance ? parseFloat(accountTokenBalance) : 0
  }

  const getPairToken = async (
    index: 0 | 1,
    pairTokensMetadata,
    pairReserves,
    accountBalances,
  ): Promise<PairToken | null> => {
    if (!chainId || !address) {
      return null
    }
    try {
      const tokenPrice = await Moralis.Web3API.token
        .getTokenPrice({
          chain: chainId,
          address: pairTokensMetadata[index].address,
        })
        .catch(() => null)
      const decimals = parseFloat(pairTokensMetadata[index].decimals)
      return {
        address: pairTokensMetadata[index].address,
        name: pairTokensMetadata[index].name,
        symbol: pairTokensMetadata[index].symbol,
        decimals,
        reserve:
          index === 0
            ? Moralis.Units.FromWei(pairReserves.reserve0, decimals)
            : Moralis.Units.FromWei(pairReserves.reserve1, decimals),
        price: tokenPrice?.usdPrice ?? 0,
        accountBalance: Moralis.Units.FromWei(
          getAccountTokenBalance(pairTokensMetadata[index].address, accountBalances),
          decimals,
        ),
      }
    } catch {
      return null
    }
  }

  const getPairTokens = async (accountAddress: string) => {
    if (!chainId || !address || !targetTokenAddress) {
      return null
    }
    const pairTokensMetadata = await getPairTokensMetadata(address, chainId)
    const pairReserves = await Moralis.Web3API.defi.getPairReserves({
      pair_address: address,
      chain: chainId,
    })
    const accountBalances = await Moralis.Web3API.account.getTokenBalances({
      address: accountAddress,
      chain: chainId,
    })
    const tokens = await Promise.all([
      getPairToken(0, pairTokensMetadata, pairReserves, accountBalances),
      getPairToken(1, pairTokensMetadata, pairReserves, accountBalances),
    ])
    if (tokens[0] && tokens[1]) {
      if (tokens[0].address.toLowerCase() !== targetTokenAddress.toLowerCase()) {
        return { tokens: [tokens[1], tokens[0]] as [PairToken, PairToken], isInverted: true }
      }
      return { tokens: tokens as [PairToken, PairToken], isInverted: false }
    }
    return null
  }

  const getPairHistoricalPrices = async (
    tokens: [PairToken, PairToken],
    isInverted: boolean,
  ): Promise<LineChartValue[] | null> => {
    if (!chainId || !address) {
      return null
    }

    const options = {
      chain: chainId,
      address,
      topic: events.pair.syncTopic,
      limit: NUMBER_OF_EVENTS,
      abi: pancakePairSyncAbi,
    }
    const pairEvents = (await Moralis.Web3API.native
      .getContractEvents(options)
      .catch(() => null)) as any
    if (!pairEvents) {
      return null
    }

    const prices = pairEvents.result.map(event => {
      return {
        time: new Date(event.block_timestamp).getTime() / 1000,
        value: isInverted
          ? Moralis.Units.FromWei(event.data.reserve0, tokens[1].decimals) /
            Moralis.Units.FromWei(event.data.reserve1, tokens[0].decimals)
          : Moralis.Units.FromWei(event.data.reserve1, tokens[1].decimals) /
            Moralis.Units.FromWei(event.data.reserve0, tokens[0].decimals),
      }
    })

    const uniquePrices = {}
    prices.forEach(priceRecord => {
      uniquePrices[priceRecord.time] = priceRecord
    })

    return linearToCandlestick(Object.values(uniquePrices))
  }

  const getMaxTargetPrice = (tokens: PairToken[]) => {
    if (!tokens[0].accountBalance || !tokens[1].accountBalance) {
      return 0
    }
    const currentReservePrice = tokens[1].reserve / tokens[0].reserve
    const token0NewReserve = Math.max(
      tokens[0].reserve - tokens[1].accountBalance / currentReservePrice,
      1,
    )
    const token1NewReserve =
      tokens[1].reserve + (tokens[0].reserve - token0NewReserve) * currentReservePrice
    const newReservePrice = token1NewReserve / token0NewReserve
    return newReservePrice
  }

  const getMinTargetPrice = (tokens: PairToken[]) => {
    if (!tokens[0].accountBalance || !tokens[1].accountBalance) {
      return 0
    }
    const currentReservePrice = tokens[1].reserve / tokens[0].reserve
    const token1NewReserve = Math.max(
      tokens[1].reserve - tokens[0].accountBalance * currentReservePrice,
      0,
    )
    const token0NewReserve =
      tokens[0].reserve + (tokens[1].reserve - token1NewReserve) / currentReservePrice

    const newReservePrice = token1NewReserve / token0NewReserve
    return newReservePrice
  }

  const getPairHistoricalTransactions = async (
    tokens: [PairToken, PairToken],
    isInverted: boolean,
  ): Promise<PairSwapTransaction[] | null> => {
    if (!address || !chainId) {
      return null
    }
    const options = {
      chain: chainId,
      address,
      topic: events.pair.swapTopic,
      limit: 10,
      abi: pancakePairSwapAbi,
    }
    const pairEvents = (await Moralis.Web3API.native.getContractEvents(options)) as any
    return pairEvents.result.map(event => {
      const { amount0In, amount0Out, amount1In, amount1Out, sender, to } = event.data
      const primaryTokenAmountIn = isInverted ? amount1In : amount0In
      const primaryTokenAmountOut = isInverted ? amount1Out : amount0Out
      const secondaryTokenAmountIn = isInverted ? amount0In : amount1In
      const secondaryTokenAmountOut = isInverted ? amount0Out : amount1Out
      const isBuy = primaryTokenAmountIn === '0'
      const primaryTokenAmount = Moralis.Units.FromWei(
        isBuy ? primaryTokenAmountOut : primaryTokenAmountIn,
        tokens[0].decimals,
      )
      const secondaryTokenAmount = Moralis.Units.FromWei(
        isBuy ? secondaryTokenAmountIn : secondaryTokenAmountOut,
        tokens[1].decimals,
      )
      const primaryTokenPrice = secondaryTokenAmount / primaryTokenAmount
      return {
        isBuy,
        isAccountTransaction: to === account,
        amount: primaryTokenAmount,
        price: primaryTokenPrice,
        sender: sender as string,
        timestamp: event.block_timestamp,
      }
    })
  }

  const refresh = async () => {
    if (!address || !chainId || !account || !isAuthenticated) {
      return
    }

    const pairTokensResult = await getPairTokens(account)
    if (!pairTokensResult) {
      console.error('Failed to get pair tokens')
      setIsLoading(false)
      setIsError(true)
      return
    }

    const { tokens, isInverted } = pairTokensResult

    const prices = await getPairHistoricalPrices(tokens, isInverted)
    if (!prices) {
      console.error('Failed to get pair historical prices')
      setIsLoading(false)
      setIsError(true)
      return
    }

    const chartData = [
      {
        data: prices,
        options: {
          title: `${tokens[1].symbol}/${tokens[0].symbol}`,
        },
      },
    ]

    setHistoricalPrices(chartData)

    const transactions = await getPairHistoricalTransactions(tokens, isInverted)
    if (!transactions) {
      console.error('Failed to get historical transactions')
      setIsLoading(false)
      setIsError(true)
      return
    }
    setHistoricalTransactions(transactions)

    setPairTokens(tokens)
    setPrice(tokens[1].reserve / tokens[0].reserve)
    setMaxTargetPrice(getMaxTargetPrice(tokens))
    setMinTargetPrice(getMinTargetPrice(tokens))
  }

  useEffect(() => {
    const interval = setInterval(refresh, REFRESH_INTERVAL)

    ;(async () => {
      setIsLoading(true)
      await refresh()
      setIsLoading(false)
    })()

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line
  }, [address, chainId, account, isAuthenticated])

  return {
    pairTokens,
    historicalPrices,
    historicalTransactions,
    price,
    maxTargetPrice,
    minTargetPrice,
    isLoading,
    isError,
  }
}
