import React from 'react'
import { Box, CloseButton, Flex, Image, Text, BoxProps } from '@chakra-ui/react'
import NavItem from './NavItem'

interface SidebarProps extends BoxProps {
  onClose: () => void
}

const SidebarContent = ({ onClose }: SidebarProps) => {
  return (
    <Box bg="gray.800" w={{ base: 'full', md: 60 }} pos="fixed" h="full">
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          <Image src="/img/logo.svg" h="40px" />
        </Text>
        <CloseButton onClick={onClose} display={{ base: 'flex', md: 'none' }} />
      </Flex>
      <NavItem target="/" icon="fas fa-home">
        Home
      </NavItem>
      <NavItem target="/onboarding" icon="fas fa-plus">
        New Bot
      </NavItem>
      <NavItem target="/dashboard" icon="fas fa-chart-line">
        Dashboard
      </NavItem>
      <NavItem target="/demo" icon="fas fa-question">
        Demo
      </NavItem>
    </Box>
  )
}

export default SidebarContent
