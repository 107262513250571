import React, { ChangeEvent, useState } from 'react'
import { contracts } from 'data/contracts'
import { Select, Container, Box, Input, Text, Button, Center, Spinner } from '@chakra-ui/react'
import { MoralisToken } from 'types/moralis/token'
import { getPairTokensMetadata } from 'utils/pair'
import { ChainId } from 'types/moralis'

type Props = {
  onSuccess: (pairAddress: string, pairTokens: MoralisToken[], tokenAddress: string) => void
}

const PairInformation = ({ onSuccess }: Props) => {
  const [pairAddress, setPairAddress] = useState<string | null>(null)
  const [pairTokens, setPairTokens] = useState<MoralisToken[] | null>(null)
  const [tokenAddress, setTokenAddress] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const submit = () => {
    if (!pairAddress || !tokenAddress || !pairTokens) {
      return
    }
    onSuccess(pairAddress, pairTokens, tokenAddress)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setPairTokens(null)
    setPairAddress(null)
    if (value.length !== 42 || value.slice(0, 2) !== '0x') {
      return
    }
    ;(async () => {
      setIsLoading(true)
      const tokens = await getPairTokensMetadata(value, contracts.onboarding.chainId as ChainId)
      if (tokens) {
        setPairTokens(tokens)
        setPairAddress(value)
      }
      setIsLoading(false)
    })()
  }

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    setTokenAddress(value)
  }

  return (
    <Container align="center" py={12}>
      <Text fontSize="3xl" py={2} fontWeight="bold">
        Pair Information
      </Text>
      <Text fontSize="xl">Provide the address of the pair and the token</Text>
      <Box pt={16}>
        <Text mb={2}>Pair Address</Text>
        <Input onChange={handleInputChange} />
      </Box>
      <Box pt={8}>
        <Text mb={2}>Target Token</Text>
        {pairTokens && !isLoading && (
          <Select onChange={handleSelectChange} defaultValue="unselected">
            <option value="unselected" disabled>
              Select target token
            </option>
            {pairTokens.map(token => (
              <option value={token.address} key={token.address}>
                {token.symbol} | {token.name}
              </option>
            ))}
          </Select>
        )}
        {isLoading && (
          <Center>
            <Spinner size="lg" mt={3} />
          </Center>
        )}
        {!pairTokens && !isLoading && (
          <Text fontSize="lg" fontWeight="bold">
            Please enter a valid pair address
          </Text>
        )}
      </Box>
      <Box pt={12}>
        <Button
          disabled={!pairAddress || !tokenAddress}
          onClick={submit}
          colorScheme="blue"
          fontSize="xl"
          p={6}
          mt={4}
        >
          Continue
        </Button>
      </Box>
    </Container>
  )
}

export default PairInformation
