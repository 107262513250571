import React, { ReactNode } from 'react'
import { Text, Box, Circle, HStack } from '@chakra-ui/react'

type Step = {
  number: number
  label: string
  component: ReactNode
}

type Props = {
  steps: Step[]
  currentStep: number
}

const Steps = ({ steps, currentStep }: Props) => {
  const getStepColor = (step: Step) => {
    if (step.number === currentStep) {
      return 'blue.400'
    }
    if (step.number < currentStep) {
      return 'green.500'
    }
    return 'gray.600'
  }

  return (
    <Box>
      <HStack>
        {steps.map(step => (
          <Box key={step.number} flex={1} align="center">
            <Circle w={50} h={50} fontSize="xl" fontWeight="bold" bg={getStepColor(step)}>
              {step.number}
            </Circle>
            <Text py={3} fontWeight="bold">
              {step.label}
            </Text>
          </Box>
        ))}
      </HStack>
      {steps.find(step => step.number === currentStep)?.component}
    </Box>
  )
}

export default Steps
