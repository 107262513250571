import React, { useState } from 'react'
import Moralis from 'moralis'
import {
  Container,
  Box,
  Table,
  Text,
  Tbody,
  Tr,
  Td,
  Th,
  Button,
  Thead,
  Center,
  Spinner,
} from '@chakra-ui/react'
import { useOnboarding } from 'hooks/useOnboarding'
import { MoralisToken } from 'types/moralis/token'

type Props = {
  pairAddress: string
  pairTokens: MoralisToken[]
  selectedTokenAddress: string
  onSuccess: () => void
}

const Payment = ({ pairAddress, pairTokens, selectedTokenAddress, onSuccess }: Props) => {
  const { signUpPrice, buyBot } = useOnboarding({ pairAddress })
  const [isLoading, setIsLoading] = useState(false)
  const submit = async () => {
    setIsLoading(true)
    const result = await buyBot(
      pairTokens[0].address,
      pairTokens[1].address,
      selectedTokenAddress === pairTokens[0].address ? 0 : 1,
    )
    setIsLoading(false)
    if (result) {
      onSuccess()
    }
  }

  return (
    <Container align="center" py={12}>
      <Text fontSize="3xl" py={2} fontWeight="bold">
        Payment
      </Text>
      <Text fontSize="xl">Pay the required fee to continue</Text>
      <Box pt={8}>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th colSpan={2}>DETAILS</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Pair Address</Td>
              <Td fontWeight="bold" textAlign="center">
                {pairAddress}
              </Td>
            </Tr>
            <Tr>
              <Td>Tokens in Pair</Td>
              <Td fontWeight="bold" textAlign="center">
                {pairTokens[0].symbol}, {pairTokens[1].symbol}
              </Td>
            </Tr>
            <Tr>
              <Td>Target Token</Td>
              <Td fontWeight="bold" textAlign="center">
                {selectedTokenAddress === pairTokens[0].address
                  ? pairTokens[0].symbol
                  : pairTokens[1].symbol}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Box pt={8}>
        <Box fontSize="5xl" fontWeight="bold">
          {signUpPrice !== null ? (
            `${Moralis.Units.FromWei(signUpPrice, 18).toFixed(5)} BNB`
          ) : (
            <Center py={6}>
              <Spinner size="lg" />
            </Center>
          )}
        </Box>
        {!isLoading ? (
          <Button
            onClick={submit}
            disabled={signUpPrice === null}
            colorScheme="blue"
            fontSize="xl"
            p={6}
            mt={6}
          >
            Pay Now
          </Button>
        ) : (
          <Center py={6}>
            <Spinner size="xl" />
          </Center>
        )}
      </Box>
    </Container>
  )
}

export default Payment
